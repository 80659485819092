// react
import React, {useEffect} from 'react';

// third-party
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet-async';
import { Redirect, Route, Switch } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';

// application
import Footer from './footer/Footer';
import Header from './header/Header';
import MobileHeader from './mobile/MobileHeader';
import MobileMenu from './mobile/MobileMenu';
import Quickview from './shared/Quickview';

// pages
import AccountLayout from './account/AccountLayout';
import AccountPageLogin from './account/AccountPageLogin';
import PageCart from './shop/ShopPageCart';
import PageWishlist from './shop/ShopPageWishlist';
import ShopPageCategory from './shop/ShopPageCategory';
import ShopPageOrderSuccess from './shop/ShopPageOrderSuccess';
import ShopPageProduct from './shop/ShopPageProduct';
import SitePageAboutUs from './site/SitePageAboutUs';
import SitePageContactUs from './site/SitePageContactUs';
import SitePageNotFound from './site/SitePageNotFound';
import SitePageTerms from './site/SitePageTerms';

// data stubs
import theme from '../data/theme';
import axios from "axios";
import {useProductsContext} from "../providers/DataProvider";
import ShopPageSubCategory from "./shop/ShopPageSubCategory";
import PrivateRoute from "./PrivateRouter";
import PrivacyPolicy from "./privacyPolicy/PrivacyPolicy";
import {getApiCallsUrl} from "../utils/localSwitch";
import {Loader} from "./loader/Loader";

const categoryLayouts = [
    ['/shop/category-grid-3-columns-sidebar', { columns: 3, viewMode: 'grid', sidebarPosition: 'start' }],
    ['/shop/category-grid-4-columns-full', { columns: 4, viewMode: 'grid' }],
    ['/shop/category-grid-5-columns-full', { columns: 5, viewMode: 'grid' }],
    ['/shop/category/:category', { columns: 3, viewMode: 'list', sidebarPosition: 'start' }],
    ['/shop/category-right-sidebar', { columns: 3, viewMode: 'grid', sidebarPosition: 'end' }],
].map(([url, options]) => (
    <Route
        key={url}
        exact
        path={url}
        render={(props) => <ShopPageCategory {...props} {...options}/>}
    />
));

const productLayouts = [
    ['/shop/product-standard', { layout: 'standard' }],
    ['/shop/product-columnar', { layout: 'columnar' }],
    ['/shop/product-sidebar', { layout: 'sidebar' }],
].map(([url, options]) => (
    <Route
        key={url}
        exact
        path={url}
        render={(props) => <ShopPageProduct {...props} {...options} productSlug="brandix-screwdriver-screw1500acc" />}
    />
));

function Layout(props) {
    const { match, headerLayout, homeComponent } = props;
    const { loadProducts, loadCategories, loadBrands, loadSubCategorie, loadCustomHeroSlides,loadRifacimentoSlides,disableLoader,stateData } = useProductsContext();
    const {loader} = stateData;
    useEffect(() => {
        const fetchData = () => {
            const callUrl = getApiCallsUrl();
            return Promise.all([
                axios.get(callUrl + 'categories', {
                    headers: {
                        Authorization: 'Bearer ' + process.env.REACT_APP_STRAPI_TOKEN
                    },
                    params: {
                        populate: '*'
                    }
                }),
                axios.get(callUrl + 'products', {
                    headers: {
                        Authorization: 'Bearer ' + process.env.REACT_APP_STRAPI_TOKEN
                    },
                    params: {
                        populate: '*'
                    }
                }),
                axios.get(callUrl+ 'brands', {
                    headers: {
                        Authorization: 'Bearer ' + process.env.REACT_APP_STRAPI_TOKEN
                    },
                    params: {
                        populate: '*'
                    }
                }),
                axios.get(callUrl + 'subcategories', {
                    headers: {
                        Authorization: 'Bearer ' + process.env.REACT_APP_STRAPI_TOKEN
                    },
                    params: {
                        populate: '*'
                    }
                }),
                axios.get(callUrl + 'carousels', {
                    headers: {
                        Authorization: 'Bearer ' + process.env.REACT_APP_STRAPI_TOKEN
                    },
                    params: {
                        populate: '*'
                    }
                }),
                axios.get(callUrl + 'rifacimento-slides', {
                    headers: {
                        Authorization: 'Bearer ' + process.env.REACT_APP_STRAPI_TOKEN
                    },
                    params: {
                        populate: '*'
                    }
                })
            ])
                .then(([responseC, responseP, responseB, responseFC, responseHS,responseRS]) => {
                    const categories = responseC.data.data;
                    const products = responseP.data.data;
                    const brands = responseB.data.data;
                    const subCategories = responseFC.data.data;
                    const customHeroSlides = responseRS.data.data;
                    const rifacimentoSlides = responseHS.data.data;

                    if (categories.length === 0) {
                        loadCategories([]);
                    } else {
                        loadCategories(categories);
                    }

                    if (products.length === 0) {
                        loadProducts([]);
                    } else {
                        loadProducts(products);
                    }

                    if (brands.length === 0) {
                        loadBrands([]);
                    } else {
                        loadBrands(brands);
                    }

                    if (subCategories.length === 0) {
                        loadSubCategorie([]);
                    } else {
                        loadSubCategorie(subCategories);
                    }

                    if (customHeroSlides.length === 0) {
                        loadCustomHeroSlides([]);
                    } else {
                        loadCustomHeroSlides(customHeroSlides);
                    }

                    if (rifacimentoSlides.length === 0) {
                        loadRifacimentoSlides([]);
                    } else {
                        loadRifacimentoSlides(rifacimentoSlides);
                    }

                    disableLoader(false);
                })
                .catch(error => {
                    console.error(error);
                });
        };

        fetchData();
    }, []);


    return (
        <React.Fragment>
            <Loader loading={loader}/>
            <Helmet>
                <title>{theme.name}</title>
                <meta name="description" content={theme.fullName} />
            </Helmet>

            <ToastContainer autoClose={5000} hideProgressBar />

            <Quickview />

            <MobileMenu />

            <div className="site">
                <header className="site__header d-lg-none">
                    <MobileHeader />
                </header>

                <header className="site__header d-lg-block d-none">
                    <Header layout={headerLayout} />
                </header>

                <div className="site__body">
                    {!loader && <Switch>
                        {/*
                        // Home
                        */}
                        <Route exact path={`${match.path}`} component={homeComponent} />

                        {/*
                        // Shop
                        */}
                        <Redirect exact from="/shop" to="/shop/catalog" />
                        <Route
                            exact
                            path="/shop/catalog"
                            render={(props) => (
                                <ShopPageCategory {...props} columns={3} viewMode="grid" sidebarPosition="start" />
                            )}
                        />
                        <Route
                            exact
                            path="/shop/catalog/:categorySlug"
                            render={({ match, ...props }) => {
                                const { params } = match;
                                return (
                                    <ShopPageCategory
                                        {...props}
                                        columns={3}
                                        viewMode="grid"
                                        sidebarPosition="start"
                                        categorySlug={params.categorySlug}
                                    />
                                );
                            }}
                        />
                        <Route
                            exact
                            path="/shop/catalog/subCategory/:categorySlug/:subCategorySlug"
                            render={({ match, ...props }) => {
                                const { params } = match;
                                return (
                                    <ShopPageSubCategory
                                        {...props}
                                        columns={3}
                                        viewMode="grid"
                                        sidebarPosition="start"
                                        categorySlug={params.subCategorySlug}
                                    />
                                );
                            }}
                        />

                        {/* Following category layouts only for demonstration. */}
                        {categoryLayouts}

                        <Route
                            exact
                            path="/shop/products/:productSlug"
                            render={({ match }) => {
                                const { params } = match;
                                return (
                                    <ShopPageProduct
                                        {...props}
                                        layout="standard"
                                        productSlug={params.productSlug}
                                    />
                                );
                            }}
                        />
                        {/* Following product layouts only for demonstration. */}
                        {productLayouts}

                        <Route exact path="/shop/cart" component={PageCart} />
                        <Route exact path="/shop/checkout/success" component={ShopPageOrderSuccess} />
                        <Route exact path="/shop/wishlist" component={PageWishlist} />

                        {/*
                        // Account
                        */}
                        <Route exact path="/account/login" component={AccountPageLogin} />
                        <PrivateRoute
                            path="/account"
                            component={AccountLayout}
                        />
                        <Route exact path="/privacy-policy" component={PrivacyPolicy} />
                        {/*
                        // Site
                        */}
                        <Redirect exact from="/site" to="/site/about-us" />
                        <Route exact path="/site/about-us" component={SitePageAboutUs} />
                        <Route exact path="/site/contact-us" component={SitePageContactUs} />
                        <Route exact path="/site/not-found" component={SitePageNotFound} />
                        <Route exact path="/site/terms" component={SitePageTerms} />

                        {/*
                        // Page Not Found
                        */}
                        <Route component={SitePageNotFound} />
                    </Switch>}
                </div>

                <footer className="site__footer">
                    <Footer />
                </footer>
            </div>
        </React.Fragment>
    );
}

Layout.propTypes = {
    /**
     * header layout (default: 'classic')
     * one of ['classic', 'compact']
     */
    headerLayout: PropTypes.oneOf(['default', 'compact']),
    /**
     * home component
     */
    homeComponent: PropTypes.elementType.isRequired,
};

Layout.defaultProps = {
    headerLayout: 'default',
};

export default Layout;
