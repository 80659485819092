export default {
    name: 'EdilPeg',
    fullName: 'EdilPeg - Effettuiamo ristrutturazioni complete di certificazioni, manutenzioni e nuove costruzioni.',
    url: 'https://themeforest.net/item/stroyka-tools-store-react-ecommerce-template/23909258',
    author: {
        name: 'Lorenzo Ceglia',
        profile_url: 'https://www.linkedin.com/in/lorenzo-ceglia/',
    },
    contacts: {
        address: 'S.S. Pontina Km 77, Latina(LT) 04100',
        email: 'info@edilpegristrutturazioni.it',
        phone: '3203023684',
    },
    social_networks: {
        instagram : 'https://instagram.com/edilpeg?igshid=MzRlODBiNWFlZA==',
        facebook : 'https://www.facebook.com/edilpeg',
        tiktok : 'https://www.tiktok.com/@edilpegristrutturazioni?_t=8et3JwWeCXp&_r=1'
    },
    p_iva : '03234070591'
};
