// react
import React, {useMemo, useState} from 'react';

// third-party
import classNames from 'classnames';
import {connect} from 'react-redux';
import {Link} from 'react-router-dom';

// application
import AsyncAction from '../shared/AsyncAction';
import Currency from '../shared/Currency';
import Indicator from './Indicator';
import {Cart20Svg, Cross10Svg} from '../../svg';
import {cartRemoveItem} from '../../store/cart';
import {url} from '../../services/utils';
import {loadStripe} from "@stripe/stripe-js";
import axios from "axios";
import useAuth from "../../hooks/useAuth";
import {getApiCallsUrl, getMediaCallsUrl} from "../../utils/localSwitch";
import {useProductsContext} from "../../providers/DataProvider";

function IndicatorCart(props) {
    const {cart, cartRemoveItem} = props;
    let dropdown;
    let totals;
    const stripePromise = useMemo(() => loadStripe('pk_test_51NJM2HH1kIu5IWFsjA7uryPGqfAJvt2HutcvHkpIdNuHZk3nuxCqyomKENL3rXmnklrlLmKnGSBQcjXgGh84dj3T00OkoG6PNv'), []);
    const currentUser = useAuth();
    const { enableLoader } = useProductsContext();

    const handleCheckout = async (e) => {
        e.preventDefault();
        enableLoader(true);
        try {
            const stripe = await stripePromise;
            const res = await axios.post(
                getApiCallsUrl() + 'orders',
                {
                    email: currentUser?.email,
                    products: cart.items,
                    status: 'attesa',
                },
                {
                    headers: {
                        Authorization: 'Bearer ' + process.env.REACT_APP_STRAPI_TOKEN
                    },
                }
            );
            await stripe.redirectToCheckout({
                    sessionId: res.data.stripeSession.id,
                }
            )
        } catch (e) {
            console.log('errore', e)
        }
    }

    if (cart.extraLines.length > 0) {
        const extraLines = cart.extraLines.map((extraLine, index) => (
            <tr key={index}>
                <th>{extraLine.title}</th>
                <td>{extraLine.price < 1 ? extraLine.price * 100 + '%' : <Currency value={extraLine.price}/>}</td>
            </tr>
        ));

        totals = (
            <React.Fragment>
                <tr>
                    <th>Totale</th>
                    <td><Currency value={cart.subtotal}/></td>
                </tr>
                {extraLines}
            </React.Fragment>
        );
    }

    const items = cart.items.map((item) => {
        let options;
        let image;

        const gallery = item.product.images.data.map(photo => getMediaCallsUrl() + photo.attributes.url)


        if (gallery) {
            image = (
                <div className="product-image dropcart__product-image">
                    <Link to={'/shop/products/' + item.product.slug} className="product-image__body">
                        <img className="product-image__img" src={gallery[0]} alt=""/>
                    </Link>
                </div>
            );
        }

        const removeButton = (
            <AsyncAction
                action={() => cartRemoveItem(item.id)}
                render={({run, loading}) => {
                    const classes = classNames('dropcart__product-remove btn btn-light btn-sm btn-svg-icon', {
                        'btn-loading': loading,
                    });

                    return (
                        <button type="button" onClick={run} className={classes}>
                            <Cross10Svg/>
                        </button>
                    );
                }}
            />
        );

        return (
            <div key={item.id} className="dropcart__product">
                {image}
                <div className="dropcart__product-info">
                    <div className="dropcart__product-name">
                        <Link to={'/shop/prdouct/' + item.slug}>{item.product.title}</Link>
                    </div>
                    {options}
                    <div className="dropcart__product-meta">
                        <span className="dropcart__product-quantity">{item.quantity}</span>
                        {' × '}
                        <span className="dropcart__product-price"><Currency value={item.price}/></span>
                    </div>
                </div>
                {removeButton}
            </div>
        );
    });
    if (cart.quantity) {
        dropdown = (
            <div className="dropcart">
                <div className="dropcart__products-list">
                    {items}
                </div>

                <div className="dropcart__totals">
                    <table>
                        <tbody>
                        {totals}
                        <tr>
                            <th>Totale</th>
                            <td><Currency value={cart.total}/></td>
                        </tr>
                        </tbody>
                    </table>
                </div>

                <div className="dropcart__buttons">
                    <Link className="btn btn-secondary mr-0" to="/shop/cart">Vedi Carrello</Link>
                    <div className="btn btn-primary" onClick={(e) => handleCheckout(e)}>Checkout
                    </div>
                </div>
            </div>
        );
    } else {
        dropdown = (
            <div className="dropcart">
                <div className="dropcart__empty">
                    Il tuo carrello è vuoto!
                </div>
            </div>
        );
    }

    return (
        <Indicator url="/shop/cart" dropdown={dropdown} value={cart.quantity} icon={<Cart20Svg/>}/>
    );
}

const mapStateToProps = (state) => ({
    cart: state.cart,
});

const mapDispatchToProps = {
    cartRemoveItem,
};

export default connect(mapStateToProps, mapDispatchToProps)(IndicatorCart);
