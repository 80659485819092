import React, {useEffect, useRef, useState} from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {Link} from 'react-router-dom';
import departmentsAria from '../../services/departmentsArea';
import languages from '../../i18n';
import StroykaSlick from '../shared/StroykaSlick';
import {useProductsContext} from "../../providers/DataProvider";
import {getMediaCallsUrl} from "../../utils/localSwitch";

const slickSettings = {
    dots: true,
    arrows: false,
    infinite: true,
    speed: 400,
    slidesToShow: 1,
    slidesToScroll: 1,
};

const BlockSlideShowRifacimentoBagno = ({locale, withDepartments, rifacimento}) => {
    const departmentsAreaRef = useRef(null);
    const media = window.matchMedia('(min-width: 992px)');

    const [width, setWidth] = useState(window.innerWidth);

    function handleWindowSizeChange() {
        setWidth(window.innerWidth);
    }

    useEffect(() => {
        window.addEventListener('resize', handleWindowSizeChange);
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        }
    }, []);

    const isMobile = width <= 768;

    useEffect(() => {
        const onChangeMedia = () => {
            if (media.matches) {
                departmentsAria.area = departmentsAreaRef.current;
            }
        };

        if (media.addEventListener) {
            media.addEventListener('change', onChangeMedia);
        } else {
            // noinspection JSDeprecatedSymbols
            media.addListener(onChangeMedia);
        }

        return () => {
            departmentsAria.area = null;

            if (media.removeEventListener) {
                media.removeEventListener('change', onChangeMedia);
            } else {
                // noinspection JSDeprecatedSymbols
                media.removeListener(onChangeMedia);
            }
        };
    }, [locale, media]);

    const setDepartmentsAreaRef = (ref) => {
        departmentsAreaRef.current = ref;

        if (media.matches) {
            departmentsAria.area = departmentsAreaRef.current;
        }
    };

    const blockClasses = classNames('block-slideshow block', {
        'block-slideshow--layout--full mt-0': !withDepartments,
        'block-slideshow--layout--with-departments': withDepartments,
    });

    const layoutClasses = classNames('col-12', {
        'col-lg-12 p-0': !withDepartments,
        'col-lg-9': withDepartments,
    });

    console.log(rifacimento)
    const slides = rifacimento.attributes.classicImg.data.map((image, index) => {
        return (
            <div key={index} className="block-slideshow__slide">
                <div className="block-slideshow__slide-image-wrapper">
                    <div
                         className="block-slideshow__slide-image block-slideshow__slide-image--desktop"
                         style={{
                             backgroundImage: `url(${getMediaCallsUrl() + image.attributes.url})`,
                         }}
                    />
                </div>
            </div>
        );
    });


    return (
        <div className={blockClasses}>
            <div className="container">
                <div className="row">
                    {withDepartments && (
                        <div className="col-3 d-lg-block d-none" ref={setDepartmentsAreaRef}/>
                    )}

                    <div className={layoutClasses}>
                        <div className="block-slideshow__body p-0">
                            <StroykaSlick {...slickSettings}>{slides}</StroykaSlick>
                        </div>
                    </div>

                    <div className={'container mt-4 p-0'}>
                        <p>
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec
                            facilisis neque ut purus fermentum, ac pretium nibh facilisis.
                            Vivamus venenatis viverra iaculis. Suspendisse tempor orci non
                            sapien ullamcorper dapibus. Suspendisse at velit diam. Donec
                            pharetra nec enim blandit vulputate.
                        </p>
                    </div>

                </div>
            </div>
        </div>
    );
};

BlockSlideShowRifacimentoBagno.propTypes = {
    withDepartments: PropTypes.bool,
    /** current locale */
    locale: PropTypes.string,
};

BlockSlideShowRifacimentoBagno.defaultProps = {
    withDepartments: false,
};

const mapStateToProps = (state) => ({
    locale: state.locale,
});

export default connect(mapStateToProps)(BlockSlideShowRifacimentoBagno);
