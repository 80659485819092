import styles from './loader.module.css';
import {Oval} from "react-loader-spinner";

import { LogoSvg } from '../../svg';

export const Loader = ({loading}) => {
    return (
        <div className={`${styles.site_preloader} ${!loading ? styles.site_preloader_fade : ''}`}>
            <LogoSvg/>
            <Oval
                height={45}
                width={45}
                color="#d34748"
                wrapperStyle={{}}
                wrapperClass=""
                visible={true}
                ariaLabel='oval-loading'
                secondaryColor="#f1f1f1"
                strokeWidth={2}
                strokeWidthSecondary={2}

            />
        </div>
    )
}
