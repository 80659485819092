import CategoryFilterBuilder from '../filters/category';
import productsData from '../database/products';
import RangeFilterBuilder from '../filters/price';
import axios from "axios";
import {getApiCallsUrl} from "../../utils/localSwitch";

function getProducts(shift, options) {
    const limit = options.limit || 8;
    let products = [...productsData.slice(shift), ...productsData.slice(0, shift)];

    if (options.category === 'power-tools') {
        products = products.slice().reverse();
    } else if (options.category === 'hand-tools') {
        products = [...products.slice(8), ...products.slice(0, 8)];
    } else if (options.category === 'plumbing') {
        products = [...products.slice(8), ...products.slice(0, 8)].reverse();
    }

    return new Promise((resolve) => {
        setTimeout(() => {
            resolve(products.slice(0, limit));
        }, 500);
    });
}

export function getProductBySlug(slug) {
    const product = productsData.find((x) => x.slug === slug);

    return product ? Promise.resolve(product) : Promise.reject();
}

export function getRelatedProducts(slug, options = {}) {
    const limit = options.limit || 8;

    return Promise.resolve(productsData.slice(0, limit));
}

const handleStrapiFilters = (filterValues) => {
    let formattedFilters = {}
    Object.keys(filterValues).map(f => {
        switch (f) {
            case 'category' :
                formattedFilters = {
                    ...formattedFilters, 'category': {
                        slug: {
                            $eq: filterValues.category
                        }
                    }
                }
                break;
            case 'price' :
                formattedFilters = {
                    ...formattedFilters, 'price': {
                        $gt: filterValues[f].split("-")[0],
                        $lt: filterValues[f].split("-")[1]
                    }
                }
                break;
        }
    })
    return formattedFilters;
}

export async function getProductsList(options = {}, filterValues = {}) {
    const filters = [
        new CategoryFilterBuilder('category', 'Categorie'),
        new RangeFilterBuilder('price', 'Prezzo'),
        // new CheckFilterBuilder('brand', 'Brand'),
    ];
    const response = await axios.get(
        getApiCallsUrl() + 'products',
        {
            headers: {
                Authorization: 'Bearer ' + process.env.REACT_APP_STRAPI_TOKEN
            },
            params: {
                populate: '*',
                filters: handleStrapiFilters(filterValues)
            },

        }
    )
    let items = response.data.data;


    filters.forEach((filter) => filter.makeItems(items, filterValues[filter.slug]));
    // Calculate items count for filter values.
    // filters.forEach((filter) => filter.calc(filters));

    // Apply filters to products list.
    /*items = items.filter(
        (product) => filters.reduce((mr, filter) => mr && filter.test(product), true),
    );*/

    const page = options.page || 1;
    const limit = options.limit || 12;
    const sort = options.sort || 'default';
    const total = items.length;
    const pages = Math.ceil(total / limit);
    const from = (page - 1) * limit + 1;
    const to = Math.max(Math.min(page * limit, total), from);

    items = items.sort((a, b) => {
        if (['name_asc', 'name_desc'].includes(sort)) {
            if (a.name === b.name) {
                return 0;
            }

            return (a.name > b.name ? 1 : -1) * (sort === 'name_asc' ? 1 : -1);
        }

        return 0;
    });

    const start = (page - 1) * limit;
    const end = start + limit;

    items = items.slice(start, end);

    return new Promise((resolve) => {
        setTimeout(() => {
            resolve({
                page,
                limit,
                sort,
                total,
                pages,
                from,
                to,
                items,
                filters: filters.map((x) => x.build()),
            });
        }, 350);
    });
}

const handleRifacimentoFilters = (filterValues, price) => {
    let condition = true;

    if (filterValues['price']) {
        Object.keys(filterValues).forEach(f => {
            switch (f) {
                case 'price':
                    const [minPrice, maxPrice] = filterValues[f].split("-").map(parseFloat);
                    condition = price >= minPrice && price <= maxPrice;
                    break;
            }
        });
    }

    return condition;
};

export function getFilters(items,options = {}, filterValues = {}) {
    const filters = [
        new CategoryFilterBuilder('category', 'Categorie'),
        new RangeFilterBuilder('price', 'Prezzo'),
        // new CheckFilterBuilder('brand', 'Brand'),
    ];

    filters.forEach((filter) => filter.makeItems(items, filterValues[filter.slug]));

    const filteredItems = items.filter(i => {
        return handleRifacimentoFilters(filterValues, i.attributes.price);
    });
    const page = options.page || 1;
    const limit = options.limit || 12;
    const sort = options.sort || 'default';
    const total = items.length;
    const pages = Math.ceil(total / limit);
    const from = (page - 1) * limit + 1;
    const to = Math.max(Math.min(page * limit, total), from);

    items = items.sort((a, b) => {
        if (['name_asc', 'name_desc'].includes(sort)) {
            if (a.name === b.name) {
                return 0;
            }

            return (a.name > b.name ? 1 : -1) * (sort === 'name_asc' ? 1 : -1);
        }

        return 0;
    });

    const start = (page - 1) * limit;
    const end = start + limit;

    items = items.slice(start, end);

    return new Promise((resolve) => {
        setTimeout(() => {
            resolve({
                page,
                limit,
                sort,
                total,
                pages,
                from,
                to,
                items : filteredItems,
                filters: filters.map((x) => x.build()),
            });
        }, 350);
    });
}

export function getFeaturedProducts(options = {}) {
    return getProducts(0, options);
}

export function getLatestProducts(options = {}) {
    return getProducts(0, options);
}

export function getTopRatedProducts(options = {}) {
    return getProducts(0, options);
}

export function getDiscountedProducts(options = {}) {
    return getProducts(3, options);
}

export function getPopularProducts(options = {}) {
    return getProducts(6, options);
}

export async function getSuggestions(query, options) {
    const limit = options.limit || 5;
    const our_prods = await axios.get(
        getApiCallsUrl() + 'products',
        {
            headers: {
                Authorization: 'Bearer ' + process.env.REACT_APP_STRAPI_TOKEN
            },
            params: {
                populate: '*',
                filters: {
                    category: {
                        slug: {
                            $eq: options.category
                        }
                    },
                }
            }
        }
    );
    const prodotti = our_prods.data.data;
    return Promise.resolve(
        prodotti.filter(
            (x) => x.attributes.title.toLowerCase().includes(query.toLowerCase()),
        ).slice(0, limit),
    );
}
