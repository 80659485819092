import {createContext, useContext, useMemo, useReducer} from 'react';

const dataReducer = (state, action) => {
    switch (action.type) {
        case 'RESET':
            return initialState;
        case 'LOAD_CATEGORIE': {
            const categorie = action.payload;
            const categorieFeatured = categorie.filter(c => c.attributes.mostWanted)
            return {...state, categorie, categorieFeatured};
        }
        case 'LOAD_SUB_CATEGORIE': {
            const subCategorie = action.payload;
            return {...state, subCategorie};
        }
        case 'LOAD_PRODOTTI': {
            const prodotti = action.payload;
            const prodottiFeatured = prodotti.filter(prd => prd.attributes.type === 'primopiano');
            return {...state, prodotti, prodottiFeatured};
        }
        case 'LOAD_BRANDS': {
            const brands = action.payload;
            return {...state, brands};
        }
        case 'LOAD_HERO_SLIDES': {
            const rifacimentoSlides = action.payload;
            return {...state, rifacimentoSlides};
        }
        case 'LOAD_RIFACIMENTO_SLIDES': {
            const customHeroSlides = action.payload;
            return {...state, customHeroSlides};
        }
        case 'ENABLE_LOADER': {
            return {...state, loader: true};
        }
        case 'DISABLE_LOADER': {
            return {...state, loader: false};
        }
        default:
            return state;
    }
};

const initialState = {
    loader: true,
    prodotti: [],
    prodottiFeatured: [],
    categorie: [],
    subCategorie: [],
    categorieFeatured: [],
    customHeroSlides: [],
    rifacimentoSlides: [],
    brands: []

};
// Context e Provider
const DataContext = createContext(undefined);

const DataProvider = ({children}) => {
    const [data, dispatchData] = useReducer(dataReducer, initialState);

    const stateData = useMemo(() =>
            data
        , [data])

    const enableLoader = () => {
        dispatchData({type: 'ENABLE_LOADER', payload: true});
    }
    const disableLoader = () => {
        dispatchData({type: 'DISABLE_LOADER', payload: false});
    }
    const loadProducts = (progetti, backPath) => {
        dispatchData({type: 'LOAD_PRODOTTI', payload: progetti});
    };
    const loadCategories = (cateogorie) => {
        dispatchData({type: 'LOAD_CATEGORIE', payload: cateogorie});
    };

    const loadSubCategorie = (subCategorie) => {
        dispatchData({type: 'LOAD_SUB_CATEGORIE', payload: subCategorie});
    };

    const loadCustomHeroSlides = (customHeroSlides) => {
        dispatchData({type: 'LOAD_HERO_SLIDES', payload: customHeroSlides});
    };

    const loadRifacimentoSlides = (rifacimentoSlides) => {
        dispatchData({type: 'LOAD_RIFACIMENTO_SLIDES', payload: rifacimentoSlides});
    };

    const loadBrands = (brands) => {
        dispatchData({type: 'LOAD_BRANDS', payload: brands});
    };

    const getPopularProductsByCategorySlug = (slug) => {
        let result = data.prodottiFeatured;
        if(slug) {
            result = data.prodottiFeatured.filter(element => element.attributes.category.data.attributes.slug === slug)
        }
        return result
    }

    const getLatestProducts = (slug) => {
        let result = data.prodotti;
        const today = new Date();
        const twoWeeksAgo = new Date(today.getTime() - 14 * 24 * 60 * 60 * 1000); // Calcola la data di due settimane fa

        if(slug) {
            result = result.filter((element) => {
                const createdAt = new Date(element.attributes.createdAt);
                return createdAt >= twoWeeksAgo && createdAt <= today && element.attributes.category.data.attributes.slug === slug;
            });
        }
        else {
            result = result.filter((element) => {
                const createdAt = new Date(element.attributes.createdAt);
                return createdAt >= twoWeeksAgo && createdAt <= today;
            });
        }
        return result;
    }

    const getProductBySlug = (slug,setLoading) => {
        let result = [];
        if(slug) {
            result = data.prodotti.filter(element => element.attributes.slug === slug)[0]
            setLoading && setLoading(false)
        }
        return result
    }

    const getCategoryBySlug = (slug) => {
        let result = [];
        if(slug) {
            result = data.categorie.filter(element => element.attributes.slug === slug)[0]
        }
        return result
    }

    const getProductsBySubCategorySlug = (slug) => {
        let result = [];
        if(slug) {
            result = data.subCategorie.filter(element => element.attributes.slug === slug)[0].attributes.products.data
            result = result.map(subC => data.prodotti.find(p => subC.attributes.slug === p.attributes.slug))
        }
        return result
    }

    const getProductsByCategorySlug = (slug) => {
        let result = [];
        if(slug) {
            result = data.categorie.filter(element => element.attributes.slug === slug)[0].attributes.products.data
            result = result.map(subC => data.prodotti.find(p => subC.attributes.slug === p.attributes.slug))
        }
        return result
    }

    const getSubCategoryBySlug = (slug) => {
        let result = [];
        if(slug) {
            result = data.subCategorie.filter(element => element.attributes.slug === slug)[0]
        }
        return result
    }

    const contextValue = {
        stateData,
        loadBrands,
        loadProducts,
        loadCategories,
        loadSubCategorie,
        getPopularProductsByCategorySlug,
        getProductBySlug,
        getCategoryBySlug,
        getLatestProducts,
        getProductsBySubCategorySlug,
        getProductsByCategorySlug,
        getSubCategoryBySlug,
        loadCustomHeroSlides,
        loadRifacimentoSlides,
        enableLoader,
        disableLoader,
    };

    return (
        <DataContext.Provider value={contextValue}>
            {children}
        </DataContext.Provider>
    );
};

// Hook per accedere al context
const useProductsContext = () => {
    const context = useContext(DataContext);
    if (context === undefined) {
        throw new Error('useProjectContext must be used within ProjectsProvider');
    }
    return context;
};

export {DataProvider, useProductsContext};
