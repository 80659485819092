// react
import React, {Component} from 'react';

// third-party
import PropTypes from 'prop-types';
import {
    BrowserRouter,
    Route,
    Redirect,
    Switch,
} from 'react-router-dom';
import {connect} from 'react-redux';
import {Helmet, HelmetProvider} from 'react-helmet-async';
import {IntlProvider} from 'react-intl';
import {ScrollContext} from 'react-router-scroll-4';

// application
import languages from '../i18n';
import {localeChange} from '../store/locale';

// pages
import Layout from './Layout';
import HomePageOne from './home/HomePageOne';
import {DataProvider} from "../providers/DataProvider";
import {UserInfoProvider} from "../providers/UserInfoProvider";

class Root extends Component {
    componentDidMount() {
        const {localeChange: changeLocale} = this.props;
        const direction = new URLSearchParams(window.location.search).get('dir');

        if (direction !== null) {
            changeLocale(direction === 'rtl' ? 'ar' : 'en');
        }
    }

    shouldUpdateScroll = (prevRouterProps, {location}) => (
        prevRouterProps && location.pathname !== prevRouterProps.location.pathname
    );

    render() {
        const {locale} = this.props;
        const {messages, direction} = languages[locale];

        return (
            <IntlProvider locale={locale} messages={messages}>
                <DataProvider>
                    <UserInfoProvider>
                        <BrowserRouter basename={process.env.PUBLIC_URL}>
                            <HelmetProvider>
                                <Helmet htmlAttributes={{lang: locale, dir: direction}}/>
                                <ScrollContext shouldUpdateScroll={this.shouldUpdateScroll}>
                                    <Switch>
                                        <Route
                                            path="/"
                                            render={(props) => (
                                                <Layout {...props} headerLayout="default" homeComponent={HomePageOne}/>
                                            )}
                                        />
                                        <Redirect to="/"/>
                                    </Switch>
                                </ScrollContext>
                            </HelmetProvider>
                        </BrowserRouter>
                    </UserInfoProvider>
                </DataProvider>
            </IntlProvider>
        );
    }
}

Root.propTypes = {
    /** current locale */
    locale: PropTypes.string,
};

const mapStateToProps = (state) => ({
    locale: state.locale,
});

const mapDispatchToProps = {
    localeChange,
};

export default connect(mapStateToProps, mapDispatchToProps)(Root);
