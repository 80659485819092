import React from 'react';
import {Helmet} from "react-helmet-async";
import theme from "../../data/theme";

const PrivacyPolicy = () => {
    return (
        <div className="block about-us">
            <Helmet>
                <title>{`Privacy Policy — ${theme.name}`}</title>
            </Helmet>

            <div className="about-us__image" style={{backgroundImage: 'url("/images/about_us_bkg.jpg")'}}/>
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-12 col-xl-10">
                        <div className="about-us__body">
                            <h1 className="about-us__title">PRIVACY POLICY</h1>
                            <div className="about-us__text typography">
                                <p><strong>Ultimo aggiornamento:</strong> 16/08/2023</p>

                                <p>Grazie per aver scelto di visitare il nostro e-commerce e utilizzare i nostri servizi. La tua privacy è importante per noi, e ci impegniamo a proteggere e rispettare i tuoi dati personali. Questa privacy policy descrive come raccogliamo, utilizziamo, trattiamo e proteggiamo le informazioni personali che ci fornisci tramite il nostro e-commerce.</p>

                                <h2>1. Introduzione</h2>

                                <p>Questa privacy policy spiega i tipi di dati personali che raccogliamo, le finalità per cui li utilizziamo, come li proteggiamo e i tuoi diritti in merito ai tuoi dati personali. Fornisce inoltre informazioni sulle tecnologie che utilizziamo, come Strapi per la gestione dei prodotti e degli ordini, Stripe per i pagamenti e le fatture, React per il front end e Firebase per l'autenticazione e la gestione della sessione autenticata.</p>

                                <h2>2. Tipi di dati raccolti</h2>

                                <p>Raccogliamo diversi tipi di dati personali quando utilizzi il nostro e-commerce, inclusi, ma non limitati a: nome, indirizzo email, indirizzo di fatturazione, dati di pagamento, informazioni sull'account e altri dati necessari per elaborare gli ordini e fornire i nostri servizi.</p>

                                <h2>3. Finalità della raccolta dei dati</h2>

                                <p>Utilizziamo i dati personali che raccogliamo per le seguenti finalità:</p>

                                <ul>
                                    <li>Elaborazione degli ordini: Utilizziamo i tuoi dati personali per elaborare e consegnare gli ordini che effettui tramite il nostro e-commerce.</li>
                                    <li>Gestione dei pagamenti e delle fatture: Selezioniamo Stripe come provider per la gestione dei pagamenti e delle fatture. I tuoi dati di pagamento saranno raccolti e trattati direttamente da Stripe, nel rispetto delle loro politiche sulla privacy e della sicurezza delle transazioni.</li>
                                    <li>Assistenza clienti: Potremmo utilizzare le tue informazioni personali per fornire assistenza e risolvere eventuali richieste o problemi che potresti avere durante l'utilizzo dei nostri servizi.</li>
                                    <li>Miglioramento del sito web: Utilizziamo dati aggregati e anonimi per analizzare le tendenze di utilizzo, migliorare la funzionalità e l'esperienza del nostro sito web.</li>
                                    <li>Comunicazioni di marketing (con il tuo consenso): Se hai fornito il consenso, potremmo inviarti newsletter o promozioni relative ai nostri prodotti o servizi tramite l'indirizzo email fornito.</li>
                                </ul>

                                <h2>4. Uso dei cookie</h2>

                                <p>Il nostro sito web non utilizza cookie o tecnologie simili per la raccolta di dati personali o fini di tracciamento. Pertanto, non è necessario gestire le impostazioni dei cookie durante la tua visita sul nostro sito.</p>

                                <h2>5. Condivisione dei dati</h2>

                                <p>Per fornire i nostri servizi, potremmo condividere i tuoi dati personali con terze parti fidate, inclusi fornitori di servizi di pagamento, fornitori di servizi di spedizione e altri partner commerciali. Tali terze parti sono tenute a proteggere la tua privacy e a utilizzare i tuoi dati personali solo per le finalità specifiche concordate con noi.</p>

                                <h2>6. Sicurezza dei dati</h2>

                                <p>Implementiamo misure di sicurezza adeguate per proteggere i tuoi dati personali da accessi non autorizzati, modifiche, divulgazioni o distruzioni. Adottiamo procedure fisiche, elettroniche e amministrative per garantire la sicurezza delle informazioni che raccogliamo.</p>

                                <h2>7. Diritti dell'utente</h2>

                                <p>Hai il diritto di accedere, correggere, aggiornare o richiedere la cancellazione dei tuoi dati personali in nostro possesso. Puoi esercitare questi diritti contattandoci tramite le informazioni di contatto fornite nella sezione successiva. Faremo del nostro meglio per rispondere alle tue richieste in modo tempestivo e conforme alle leggi applicabili.</p>

                                <h2>8. Politica sui minori</h2>

                                <p>Il nostro e-commerce non è destinato all'utilizzo da parte di minori di età inferiore a 13 anni (o all'età specificata dalle leggi sulla privacy del tuo paese). Non raccogliamo intenzionalmente dati personali da minori senza il consenso dei genitori o del tutore legale. Se scopriamo di avere raccolto dati personali di un minore senza il consenso appropriato, faremo tutto il possibile per eliminarli dai nostri sistemi.</p>

                                <h2>9. Modifiche alla privacy policy</h2>

                                <p>Questa privacy policy può essere soggetta a modifiche periodiche. Eventuali modifiche saranno pubblicate su questa pagina con una nuova data di aggiornamento. Ti invitiamo a consultare regolarmente questa pagina per verificare eventuali aggiornamenti o modifiche.</p>

                                <h2>10. Informazioni di contatto</h2>

                                <p>Se hai domande, dubbi o richieste riguardanti la nostra privacy policy o il trattamento dei tuoi dati personali, ti preghiamo di contattarci tramite le seguenti informazioni di contatto:</p>

                                <p>[Inserisci le tue informazioni di contatto, come indirizzo email o numero di telefono]</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PrivacyPolicy;
