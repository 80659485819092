// react
import React, {useMemo} from 'react';

// third-party
import {Helmet} from 'react-helmet-async';

// application
import {useProductTabs} from '../../services/hooks';

// blocks
import BlockBrands from '../blocks/BlockBrands';
import BlockCategories from '../blocks/BlockCategories';
import BlockProductsCarousel from '../blocks/BlockProductsCarousel';
import BlockSlideShow from '../blocks/BlockSlideShow';

// data stubs
import theme from '../../data/theme';
import {useProductsContext} from "../../providers/DataProvider";

function HomePageOne() {
    const {stateData, getPopularProductsByCategorySlug, getLatestProducts} = useProductsContext();
    const {prodottiFeatured, categorieFeatured, categorie} = stateData;

    const featuredProducts = useProductTabs(
        useMemo(() => {
            const uniqueProducts = [
                {
                    id: 1,
                    name: 'Tutte',
                    categorySlug: undefined
                },
                ...prodottiFeatured.map(featProd => ({
                    id: featProd.attributes.category.data.id,
                    name: featProd.attributes.category.data.attributes.title,
                    categorySlug: featProd.attributes.category.data.attributes.slug
                }))
            ];

            return Array.from(new Set(uniqueProducts.map(JSON.stringify)), JSON.parse);
        }, [prodottiFeatured]),
        tab => getPopularProductsByCategorySlug(tab.categorySlug)
    );

    const latestProducts = useProductTabs(
        useMemo(() => {
            const uniqueProducts = [
                {
                    id: 1,
                    name: 'Tutte',
                    categorySlug: undefined
                },
                ...getLatestProducts().map(featProd => ({
                    id: featProd.attributes.category.data.id,
                    name: featProd.attributes.category.data.attributes.title,
                    categorySlug: featProd.attributes.category.data.attributes.slug
                }))
            ];

            return Array.from(new Set(uniqueProducts.map(JSON.stringify)), JSON.parse);
        }, [categorie]),
        (tab) => getLatestProducts(tab.categorySlug),
    );

    return (
        <React.Fragment>
            <Helmet>
                <title>{`Home Page — ${theme.name}`}</title>
            </Helmet>

            {useMemo(() => <BlockSlideShow withDepartments/>, [])}

            {featuredProducts.length !== 0 &&
                <BlockProductsCarousel
                    title="Prodotti E Categorie In Primo Piano"
                    layout="grid-4"
                    products={featuredProducts.data}
                    loading={featuredProducts.isLoading}
                    groups={featuredProducts.tabs}
                    onGroupClick={featuredProducts.handleTabChange}
                />
            }

            {useMemo(() => (
                categorieFeatured.length !== 0 && <BlockCategories
                    title="Categorie più richieste"
                    layout="classic"
                    categories={categorieFeatured}
                />
            ), [categorieFeatured])}
            {useMemo(() => <BlockBrands/>, [])}

            {useMemo(() => (
                latestProducts.data.length !== 0 && <BlockProductsCarousel
                    title="Nuovi Arrivi"
                    layout="horizontal"
                    rows={2}
                    products={latestProducts.data}
                    loading={latestProducts.isLoading}
                    groups={latestProducts.tabs}
                    onGroupClick={latestProducts.handleTabChange}
                />
            ), [latestProducts])}

        </React.Fragment>
    );
}

export default HomePageOne;
