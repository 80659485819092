// react
import React from 'react';

// third-party
import { Link } from 'react-router-dom';

// application
import Megamenu from './Megamenu';
import Menu from './Menu';
import { ArrowRoundedRight6x9Svg } from '../../svg';

// data stubs
import {useProductsContext} from "../../providers/DataProvider";

function DepartmentsLinks() {
    const {stateData} = useProductsContext();
    const{categorie} = stateData;

    const linksList = categorie.map((category, index) => {
        let arrow = null;
        let submenu = null;
        let itemClass = '';

        if (category.attributes.sub_categories.data.length > 0) {
            arrow = <ArrowRoundedRight6x9Svg className="departments__link-arrow" />;
        }
        if (category.attributes.sub_categories.data.length > 0) {
            itemClass = 'departments__item--menu';
            submenu = (
                <div className="departments__menu">
                    <Menu categorySlug={category.attributes.slug} items={category.attributes.sub_categories.data} />
                </div>
            );
        }

        return (
            <li key={index} className={`departments__item ${itemClass}`}>
                <Link to={'/shop/category/' + category.attributes.slug}>
                    {category.attributes.title}
                    {arrow}
                </Link>
                {submenu}
            </li>
        );
    });

    return (
        <ul className="departments__links">
            <li className={`departments__item`}>
                <Link to={'/shop/category/rifacimento-bagno'}>
                    Rifacimento Bagno
                </Link>
            </li>
            {linksList}
        </ul>
    );
}

export default DepartmentsLinks;
