// react
import React from 'react';

// third-party
import { Link } from 'react-router-dom';

function Topbar() {
    const links = [
        { title: "Chi Siamo", url: '/site/about-us' },
        { title: "Contatti", url: '/site/contact-us' },
    ];

    const linksList = links.map((item, index) => (
        <div key={index} className="topbar__item topbar__item--link">
            <Link className="topbar-link" to={item.url}>{item.title}</Link>
        </div>
    ));

    return (
        <div className="site-header__topbar topbar">
            <div className="topbar__container container">
                <div className="topbar__row">
                    {linksList}
                </div>
            </div>
        </div>
    );
}

export default Topbar;
