import React, {useMemo} from "react";

export default ({elements,metratura,setMetratura}) => {
    const metrature = useMemo(() => {
        return elements.map(e => e.attributes.metratura)
    },[elements])

    return (
        <>
            <div className={'container mt-4 p-0'}>
                <p>
                    Seleziona la metratura che desideri tra i nostri bagni selezionati.
                </p>
            </div>
            <div className={'d-flex pb-3 flex-row gap-3 justify-content-center align-items-center'} style={{gap:'8px',overflowX:'scroll'}}>
                {metrature.map((m) => (<button onClick={() => setMetratura(m)} key={m} type="button" className={`btn text-nowrap btn-${metratura === m ? 'primary' :'secondary'} btn-md`}>{m} m²</button>))}
            </div>
        </>
    )
}
