import React, { useState } from 'react';
import { Helmet } from 'react-helmet-async';

import theme from '../../data/theme';
import {useUserInfoContext} from "../../providers/UserInfoProvider";

const AccountPageOrders = () => {
    const {stateData} = useUserInfoContext();
    const {orders: storeOrders} = stateData;

    const ordersList = storeOrders.map((order) => (
        <tr key={order.id}>
            <td>
                #{order.id}
            </td>
            <td>{new Date(order.attributes.createdAt).toISOString().replace(/T/, ' ').replace(/\..+/, '')}</td>
            <td><a href={order.attributes.receipt_url} target="_blank" rel="noopener noreferrer">
                Link
            </a></td>
            <td>{order.attributes.amount / 100} €</td>
        </tr>
    ));

    return (
        <div className="card">
            <Helmet>
                <title>{`Order History — ${theme.name}`}</title>
            </Helmet>

            <div className="card-header">
                <h5>Cronologia Ordini</h5>
            </div>
            <div className="card-divider" />
            <div className="card-table">
                <div className="table-responsive-sm">
                    <table>
                        <thead>
                        <tr>
                            <th>Ordine</th>
                            <th>Data</th>
                            <th>Fattura</th>
                            <th>Totale</th>
                        </tr>
                        </thead>
                        <tbody>{ordersList}</tbody>
                    </table>
                </div>
            </div>
            <div className="card-divider" />
            {/*<div className="card-footer">*/}
            {/*    <Pagination current={page} total={3} onPageChange={handlePageChange} />*/}
            {/*</div>*/}
        </div>
    );
};

export default AccountPageOrders;
