import axios from "axios";
import {getApiCallsUrl} from "../../utils/localSwitch";

export async function getCategoryBySlug(slug, options = {}) {
    const response = await axios.get(
        getApiCallsUrl() + 'categories',
        {
            headers: {
                Authorization: 'Bearer ' + process.env.REACT_APP_STRAPI_TOKEN
            },
            params: {
                populate: '*',
                filters: {
                    slug: {
                        $eq: slug
                    }
                }
            },
        }
    );
    const category = response.data.data[0];
    return category ? Promise.resolve(category) : Promise.reject();
}
