import React, {useEffect} from 'react';
import { Route, Redirect } from 'react-router-dom';
import useAuth from "../hooks/useAuth";
import {Spinner} from "reactstrap";
import axios from "axios";
import {useUserInfoContext} from "../providers/UserInfoProvider";
import {Loader} from "./loader/Loader";

const PrivateRoute = ({ component: Component, ...rest }) => {
    const user = useAuth();
    const {loadUserInfo,loadOrders} = useUserInfoContext();

    const fetchUserInfo = async () => {
        const strapi_user = await axios.get(
            process.env.REACT_APP_STRAPI_URL + 'e-commerce-users',
            {
                headers: {
                    Authorization: 'Bearer ' + process.env.REACT_APP_STRAPI_TOKEN
                },
                params: {
                    populate: '*',
                    filters: {
                        firebaseId: {
                            $eq : user.uid
                        }
                    }
                },
            }
        );
        const user_orders = await axios.get(
            process.env.REACT_APP_STRAPI_URL + 'orders',
            {
                headers: {
                    Authorization: 'Bearer ' + process.env.REACT_APP_STRAPI_TOKEN
                },
                params: {
                    populate: '*',
                    filters: {
                        email: {
                            $eq : user.email
                        }
                    },
                    sort: 'createdAt:DESC'
                },
            }
        );
        loadUserInfo(strapi_user.data.data[0]);
        loadOrders(user_orders);
    }

    useEffect(() => {
        if(user) {
            fetchUserInfo();
        }
    }, [user]);

    return (
        <Route
            {...rest}
            render={(props) =>
                typeof user === 'undefined' ? (
                    <div className={'spinner_div'}><Loader loading={true}/></div>
                ) : user ? (
                    <Component {...props} />
                ) : (
                    <Redirect to="/" />
                )
            }
        />
    );
}

export default PrivateRoute;
