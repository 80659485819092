export default [
    {
        type: 'link',
        label: 'Su di noi',
        url: '/site/about-us',
        children: [
            { type: 'link', label: 'Chi siamo', url: '/site/about-us' },
            { type: 'link', label: 'Dove siamo', url: '/site/contact-us' },
        ],
    }
];
