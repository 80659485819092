// react
import React, {useState} from 'react';

// third-party
import {Helmet} from 'react-helmet-async';
import {Link, withRouter} from 'react-router-dom';

// application
import PageHeader from '../shared/PageHeader';
import {Check9x7Svg} from '../../svg';

// data stubs
import theme from '../../data/theme';
import useAuth from "../../hooks/useAuth";
import {loginInWithEmailAndPassword, registerWithEmailAndPassword, signInWithGoogle} from "../../utils/firebase";
import {useUserInfoContext} from "../../providers/UserInfoProvider";
import ReCAPTCHA from "react-google-recaptcha";
import {toast} from "react-toastify";

function AccountPageLogin({history}) {
    const breadcrumb = [{title: 'Home', url: ''}, {title: 'Il Mio Account', url: ''},];
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");

    const [captcha, setCaptcha] = useState(false);
    const [registerEmail, setRegisterEmail] = useState("");
    const [registerPassword, setRegisterPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const navigate = (path) => {
        history.push(path);
    };

    const isFormValid = () => {
        return registerEmail.length !== 0 && registerPassword.length !== 0 && confirmPassword.length !== 0 && captcha && registerPassword === confirmPassword;
    }

    return (<React.Fragment>
            <Helmet>
                <title>{`Login — ${theme.name}`}</title>
            </Helmet>

            <PageHeader header="Il Mio Account" breadcrumb={breadcrumb}/>

            <div className="block">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6 d-flex">
                            <div className="card flex-grow-1 mb-md-0">
                                <div className="card-body">
                                    <h3 className="card-title">Login</h3>
                                    <form>
                                        <div className="form-group">
                                            <label htmlFor="login-email">Indirizzo Email</label>
                                            <input
                                                id="login-email"
                                                type="email"
                                                className="form-control"
                                                onChange={(e) => setEmail(e.target.value)}
                                                placeholder="Inserisci email"
                                            />
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="login-password">Password</label>
                                            <input
                                                id="login-password"
                                                type="password"
                                                className="form-control"
                                                onChange={(e) => setPassword(e.target.value)}
                                                placeholder="Password"
                                            />
                                            {/*<small className="form-text text-muted">*/}
                                            {/*    <Link to="/">Password Dimenticata</Link>*/}
                                            {/*</small>*/}
                                        </div>
                                        <div className="form-group">
                                            <div className="form-check">
                                                <span className="form-check-input input-check">
                                                    <span className="input-check__body">
                                                        <input
                                                            id="login-remember"
                                                            type="checkbox"
                                                            className="input-check__input"
                                                        />
                                                        <span className="input-check__box"/>
                                                        <Check9x7Svg className="input-check__icon"/>
                                                    </span>
                                                </span>
                                                <label className="form-check-label" htmlFor="login-remember">
                                                    Ricordami
                                                </label>
                                            </div>
                                        </div>
                                        <div style={{
                                            display: 'flex', justifyContent: 'start', gap: '10px', alignItems: 'center'
                                        }}>
                                            <button onClick={(e) => {
                                                e.preventDefault();
                                                loginInWithEmailAndPassword(email, password, navigate)
                                            }} className="btn btn-primary mt-2 mt-md-3 mt-lg-4">
                                                Login
                                            </button>
                                            <button onClick={(e) => {
                                                e.preventDefault();
                                                signInWithGoogle(navigate)
                                            }} className="btn btn-primary mt-2 mt-md-3 mt-lg-4 google_big_button"><img
                                                className="google-icon"
                                                src="https://upload.wikimedia.org/wikipedia/commons/5/53/Google_%22G%22_Logo.svg"/>
                                            </button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 d-flex mt-4 mt-md-0">
                            <div className="card flex-grow-1 mb-0">
                                <div className="card-body">
                                    <h3 className="card-title">Registrati</h3>
                                    <form>
                                        <div className="form-group">
                                            <label htmlFor="register-email">Indirizzo Email</label>
                                            <input
                                                id="register-email"
                                                type="email"
                                                onChange={(e) => setRegisterEmail(e.target.value)}
                                                className="form-control"
                                                placeholder="Enter email"
                                            />
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="register-password">Password</label>
                                            <input
                                                id="register-password"
                                                type="password"
                                                onChange={(e) => setRegisterPassword(e.target.value)}
                                                className="form-control"
                                                placeholder="Password"
                                            />
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="register-confirm">Ripeti Password</label>
                                            <input
                                                id="register-confirm"
                                                type="password"
                                                onChange={(e) => setConfirmPassword(e.target.value)}
                                                className="form-control"
                                                placeholder="Password"
                                            />
                                        </div>
                                        <ReCAPTCHA
                                            onErrored={() => setCaptcha(false)}
                                            onExpired={() => setCaptcha(false)}
                                            className={'pb-1'}
                                            sitekey="6LeGCNImAAAAALUaCQrTK1cnF2UXqMmQsfkHGOXC"
                                            onChange={() => setCaptcha(true)} // Funzione di callback per gestire il cambio di stato
                                        />
                                        <button onClick={(e) => {
                                            e.preventDefault();
                                            if (isFormValid()) {
                                                registerWithEmailAndPassword(registerEmail, registerPassword, navigate)
                                            } else toast.error('Form non valida!')
                                        }}
                                                disabled={!isFormValid()}
                                                className="btn btn-primary mt-md-3 mt-lg-4">
                                            Registrati
                                        </button>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>);
}

export default withRouter(AccountPageLogin);
