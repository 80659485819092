// react
import React from 'react';

// data stubs
import theme from '../../data/theme';
import FooterNewsletter from "./FooterNewsletter";

export default function FooterContacts() {
    return (
        <div className="site-footer__widget footer-contacts">
            <h5 className="footer-contacts__title">Contattaci</h5>

            <div className="footer-contacts__text">
                Ci avvaliamo di figure altamente specializzate e continuamente aggiornate. Effettuiamo ristrutturazioni complete di certificazioni, manutenzioni e nuove costruzioni.
            </div>

            <ul className="footer-contacts__contacts">
                <li>
                    <i className="footer-contacts__icon fas fa-globe-americas" />
                    {theme.contacts.address}
                </li>
                <li>
                    <i className="footer-contacts__icon far fa-envelope" />
                    {theme.contacts.email}
                </li>
                <li>
                    <i className="footer-contacts__icon fas fa-mobile-alt" />
                    {`${theme.contacts.phone}`}
                </li>
                <li>
                    <i className="footer-contacts__icon far fa-clock" />
                    Lun-Sab 09:00am - 20:00pm
                </li>
            </ul>
        </div>
    );
}
