// react
import React, {useState} from 'react';

// third-party
import {Link, withRouter} from 'react-router-dom';

// application
import Indicator from './Indicator';
import {Person20Svg} from '../../svg';
import {loginInWithEmailAndPassword, logout, signInWithGoogle} from "../../utils/firebase";
import useAuth from "../../hooks/useAuth";
import {useUserInfoContext} from "../../providers/UserInfoProvider";

function IndicatorAccount({history}) {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const currentUser = useAuth();
    const navigate = (path) => {
        history.push(path);
    };

    const dropdown = (
        <div className="account-menu">
            {!currentUser && <>
                <form className="account-menu__form">
                    <div className="account-menu__form-title">Entra con il tuo account</div>
                    <div className="form-group">
                        <label htmlFor="header-signin-email" className="sr-only">Indirizzo Email</label>
                        <input
                            id="header-signin-email"
                            type="email"
                            className="form-control form-control-sm"
                            onChange={(e) => setEmail(e.target.value)}
                            placeholder="Indirizzo Email"
                        />
                    </div>
                    <div className="form-group">
                        <label htmlFor="header-signin-password" className="sr-only">Password</label>
                        <div className="account-menu__form-forgot">
                            <input
                                id="header-signin-password"
                                type="password"
                                className="form-control form-control-sm"
                                onChange={(e) => setPassword(e.target.value)}
                                placeholder="Password"
                            />
                            {/*<Link to="/account/login" className="account-menu__form-forgot-link">Dimenticato?</Link>*/}
                        </div>
                    </div>
                    <div className="form-group account-menu__form-button">
                        <button onClick={(e) => {
                            e.preventDefault();
                            loginInWithEmailAndPassword(email, password, navigate)
                        }} className="btn btn-primary btn-sm">Login
                        </button>
                        <button onClick={(e) => {
                            e.preventDefault();
                            signInWithGoogle(navigate)
                        }} className="btn btn-primary btn-sm google_button"><img className="google-icon"
                                                                                 src="https://upload.wikimedia.org/wikipedia/commons/5/53/Google_%22G%22_Logo.svg"/>
                        </button>
                    </div>
                    <div className="account-menu__form-link">
                        <Link to="/account/login">Crea Un Account</Link>
                    </div>
                </form>
            </>}
            {currentUser && (<>
                <div className="account-menu__divider"/>
                <Link to="/account/dashboard" className="account-menu__user">
                    <div className="account-menu__user-avatar">
                        <img src="/images/avatars/placeholderuser.jpg" alt=""/>
                    </div>
                    <div className="account-menu__user-info">
                        <div className="account-menu__user-name">{currentUser.displayName}</div>
                        <div className="account-menu__user-email">{currentUser.email}</div>
                    </div>
                </Link>
                <div className="account-menu__divider"/>
                <ul className="account-menu__links">
                    <li><Link to="/account/profile">Modifica Profilo</Link></li>
                    <li><Link to="/account/orders">Cronologia Ordini</Link></li>
                    {/*<li><Link to="/account/addresses">Indirizzi</Link></li>*/}
                    <li><Link to="/account/password">Password</Link></li>
                </ul>
                <div className="account-menu__divider"/>
                <ul className="account-menu__links">
                    <li><span onClick={() => logout()}>Logout</span></li>
                </ul>
            </>)}
        </div>
    );

    return (
        <Indicator url="/account" dropdown={dropdown} icon={<Person20Svg/>}/>
    );
}

export default withRouter(IndicatorAccount);
