// react
import React from 'react';

// third-party
import {Helmet} from 'react-helmet-async';
import {Link} from 'react-router-dom';

// data stubs
import theme from '../../data/theme';
import useAuth from "../../hooks/useAuth";
import {useUserInfoContext} from "../../providers/UserInfoProvider";

export default function AccountPageDashboard() {
    const currentUser = useAuth();
    const {stateData} = useUserInfoContext();
    const {orders: storeOrders} = stateData;

    const orders = storeOrders.slice(0, 3).map((order) => (
        <tr key={order.id}>
            <td>
                #
                {order.id}
            </td>
            <td>{new Date(order.attributes.createdAt).toISOString().replace(/T/, ' ').replace(/\..+/, '')}</td>
            <td><a href={order.attributes.receipt_url} target="_blank" rel="noopener noreferrer">
                Link
            </a></td>
            <td>{order.attributes.amount / 100} €</td>
        </tr>
    ));

    return (
        <div className="dashboard">
            <Helmet>
                <title>{`Il Mio Account — ${theme.name}`}</title>
            </Helmet>

            {currentUser && <div className="dashboard__profile card profile-card">
                <div className="card-body profile-card__body">
                    <div className="profile-card__avatar">
                        <img src="/images/avatars/placeholderuser.jpg" alt=""/>
                    </div>
                    <div className="profile-card__name">{currentUser.displayName}</div>
                    <div className="profile-card__email">{currentUser.email}</div>
                    <div className="profile-card__edit">
                        <Link to="profile" className="btn btn-secondary btn-sm">Modifica Profilo</Link>
                    </div>
                </div>
            </div>}
            {/*<div className="dashboard__address card address-card address-card--featured">*/}
            {/*    {address.default && <div className="address-card__badge">Default Address</div>}*/}
            {/*    <div className="address-card__body">*/}
            {/*        <div className="address-card__name">{`${address.firstName} ${address.lastName}`}</div>*/}
            {/*        <div className="address-card__row">*/}
            {/*            {address.country}*/}
            {/*            <br />*/}
            {/*            {address.postcode}*/}
            {/*            ,*/}
            {/*            {address.city}*/}
            {/*            <br />*/}
            {/*            {address.address}*/}
            {/*        </div>*/}
            {/*        <div className="address-card__row">*/}
            {/*            <div className="address-card__row-title">Phone Number</div>*/}
            {/*            <div className="address-card__row-content">{address.phone}</div>*/}
            {/*        </div>*/}
            {/*        <div className="address-card__row">*/}
            {/*            <div className="address-card__row-title">Email Address</div>*/}
            {/*            <div className="address-card__row-content">{address.email}</div>*/}
            {/*        </div>*/}
            {/*        <div className="address-card__footer">*/}
            {/*            <Link to="/account/addresses/5">Edit Address</Link>*/}
            {/*        </div>*/}
            {/*    </div>*/}
            {/*</div>*/}
            <div className="dashboard__orders card">
                <div className="card-header">
                    <h5>Ordini Recenti</h5>
                </div>
                <div className="card-divider"/>
                <div className="card-table">
                    <div className="table-responsive-sm">
                        <table>
                            <thead>
                            <tr>
                                <th>Ordine</th>
                                <th>Data</th>
                                <th>Ricevuta</th>
                                <th>Totale</th>
                            </tr>
                            </thead>
                            <tbody>
                            {orders}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    );
}
