// react
import React from 'react';

export default function BlockMap() {
    return (
        <div className="block-map block">
            <div className="block-map__body">
                <iframe
                    title="Google Map"
                    src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d2991.5722622546787!2d12.951758075977079!3d41.426802993549444!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zNDHCsDI1JzM2LjUiTiAxMsKwNTcnMTUuNiJF!5e0!3m2!1sit!2sit!4v1685045476785!5m2!1sit!2sit"
                    frameBorder="0"
                    scrolling="no"
                    marginHeight="0"
                    marginWidth="0"
                />
            </div>
        </div>
    );
}
