import {createContext, useContext, useMemo, useReducer} from 'react';

const dataReducer = (state, action) => {
    switch (action.type) {
        case 'RESET':
            return initialState;
        case 'LOAD_INFO': {
            const info = action.payload?.attributes;
            return {...state, ...info, id: action?.payload?.id};
        }
        case 'RELOAD_INFO': {
            const info = action.payload;
            return {...state, ...info};
        }
        case 'LOAD_ORDERS': {
            const orders = action.payload.data.data;
            return {...state, orders};
        }
        default:
            return state;
    }
};

const initialState = {
    orders: [],
    phone: '',
    name: '',
    surname: '',
    createdAt: '',
    updatedAt: '',
    firebaseId: ''
};
// Context e Provider
const UserInfoContext = createContext(undefined);

const UserInfoProvider = ({children}) => {
    const [data, dispatchData] = useReducer(dataReducer, initialState);

    const stateData = useMemo(() =>
            data
        , [data])

    const loadUserInfo = (info) => {
        dispatchData({type: 'LOAD_INFO', payload: info});
    };

    const reloadUserInfo = (info) => {
        dispatchData({type: 'RELOAD_INFO', payload: info});
    }

    const loadOrders = (orders) => {
        dispatchData({type: 'LOAD_ORDERS', payload: orders});
    };

    const contextValue = {
        stateData,
        loadUserInfo,
        loadOrders,
        reloadUserInfo
    };
    return (
        <UserInfoContext.Provider value={contextValue}>
            {children}
        </UserInfoContext.Provider>
    );
};

// Hook per accedere al context
const useUserInfoContext = () => {
    const context = useContext(UserInfoContext);
    if (context === undefined) {
        throw new Error('useProjectContext must be used within ProjectsProvider');
    }
    return context;
};

export {UserInfoProvider, useUserInfoContext};
