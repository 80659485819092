import React, { useState, useEffect, useRef } from 'react';
import departmentsArea from '../../services/departmentsArea';
import DepartmentsLinks from './DepartmentsLinks';
import { Menu18x14Svg, ArrowRoundedDown9x6Svg } from '../../svg';

function Departments() {
    const [open, setOpen] = useState(false);
    const [fixed, setFixed] = useState(false);
    const [area, setArea] = useState(null);
    const rootRef = useRef(null);

    useEffect(() => {
        function handleOutsideClick(event) {
            if (rootRef.current && !rootRef.current.contains(event.target)) {
                setOpen(false);
            }
        }

        document.addEventListener('mousedown', handleOutsideClick);

        const unsubscribeAria = departmentsArea.subscribe((area) => {
            setFixed(!!area);
            setArea(area);
        });

        setFixed(!!departmentsArea.area);
        setArea(departmentsArea.area);

        return () => {
            document.removeEventListener('mousedown', handleOutsideClick);
            unsubscribeAria();
        };
    }, []);

    useEffect(() => {
        const root = rootRef.current;
        const content = root.querySelector('.departments__links-wrapper');

        if (fixed) {
            const areaRect = area.getBoundingClientRect();
            const areaBottom = areaRect.top + areaRect.height + window.scrollY;

            root.classList.remove('departments--transition');
            root.classList.add('departments--fixed', 'departments--opened');

            const height = areaBottom - (content.getBoundingClientRect().top + window.scrollY);

            content.style.height = `${height}px`;
            content.getBoundingClientRect(); // force reflow
        } else {
            if (open) {
                const startHeight = content.getBoundingClientRect().height;

                root.classList.add('departments--transition', 'departments--opened');

                const endHeight = content.getBoundingClientRect().height;

                content.style.height = `${startHeight}px`;
                content.getBoundingClientRect(); // force reflow
                content.style.height = `${endHeight}px`;
            } else {
                const startHeight = content.getBoundingClientRect().height;

                content.style.height = `${startHeight}px`;

                root.classList.add('departments--transition');
                root.classList.remove('departments--opened');

                content.getBoundingClientRect(); // force reflow
                content.style.height = '';
            }
        }
    }, [fixed, open]);

    function handleButtonClick() {
        setOpen((prevOpen) => !prevOpen);
    }

    function handleTransitionEnd(event) {
        if (rootRef.current && event.propertyName === 'height') {
            rootRef.current.querySelector('.departments__links-wrapper').style.height = '';
            rootRef.current.classList.remove('departments--transition');
        }
    }

    return (
        <div className="departments" ref={rootRef}>
            <div className="departments__body">
                <div className="departments__links-wrapper" onTransitionEnd={handleTransitionEnd}>
                    <DepartmentsLinks />
                </div>
            </div>

            <button type="button" className="departments__button" onClick={handleButtonClick}>
                <Menu18x14Svg className="departments__button-icon" />
                Categorie
                <ArrowRoundedDown9x6Svg className="departments__button-arrow" />
            </button>
        </div>
    );
}

export default Departments;
