// react
import React, {useState} from 'react';

// third-party
import { Helmet } from 'react-helmet-async';
import ReCAPTCHA from "react-google-recaptcha";

// application
import PageHeader from '../shared/PageHeader';

// blocks
import BlockMap from '../blocks/BlockMap';

// data stubs
import theme from '../../data/theme';
import {toast} from "react-toastify";
import axios from "axios";

function SitePageContactUs() {
    const breadcrumb = [
        { title: 'Home', url: '' },
        { title: 'Contattaci', url: '' },
    ];
    const [captcha,setCaptcha] = useState(false);
    const [name,setName] = useState('');
    const [email,setEmail] = useState('');
    const [subject,setSubject] = useState('');
    const [message,setMessage] = useState('');

    const handleSubmit = async (e) => {
        e.preventDefault();
        if(isFormValid) {
            try {
                const response = await axios.post(
                    process.env.REACT_APP_STRAPI_URL + 'leads',
                    {
                        data: {
                            name,
                            email,
                            subject,
                            message
                        },
                    },
                    {
                        headers: {
                            Authorization: 'Bearer ' + process.env.REACT_APP_STRAPI_TOKEN
                        },
                    }
                );
                if(response.status === 200) {
                    toast.success('Messaggio inviato correttamente!');
                }
            } catch (e) {
                toast.error('Errore!');
            }
        } else toast.error('Form non valida!');
    }

    const isFormValid = () => {
      return email.length !== 0 && subject.length !== 0 && message.length !== 0 && captcha;
    }
    return (
        <React.Fragment>
            <Helmet>
                <title>{`Contattaci — ${theme.name}`}</title>
            </Helmet>

            <BlockMap />

            <PageHeader header="Contattaci" breadcrumb={breadcrumb} />

            <div className="block">
                <div className="container">
                    <div className="card mb-0">
                        <div className="card-body contact-us">
                            <div className="contact-us__container">
                                <div className="row">
                                    <div className="col-12 col-lg-6 pb-4 pb-lg-0">
                                        <h4 className="contact-us__header card-title">Our Address</h4>

                                        <div className="contact-us__address">
                                            <p>
                                                715 Fake Ave, Apt. 34, New York, NY 10021 USA
                                                <br />
                                                Email: stroyka@example.com
                                                <br />
                                                Phone Number: +1 754 000-00-00
                                            </p>

                                            <p>
                                                <strong>Opening Hours</strong>
                                                <br />
                                                Monday to Friday: 8am-8pm
                                                <br />
                                                Saturday: 8am-6pm
                                                <br />
                                                Sunday: 10am-4pm
                                            </p>

                                            <p>

                                                <strong>Comment</strong>
                                                <br />
                                                Lorem ipsum dolor sit amet, consectetur adipiscing
                                                elit. Curabitur suscipit suscipit mi, non tempor
                                                nulla finibus eget. Lorem ipsum dolor sit amet,
                                                consectetur adipiscing elit.
                                            </p>
                                        </div>
                                    </div>

                                    <div className="col-12 col-lg-6">
                                        <h4 className="contact-us__header card-title">Lasciaci un Messaggio</h4>

                                        <form onSubmit={(e) => handleSubmit(e)}>
                                            <div className="form-row">
                                                <div className="form-group col-md-6">
                                                    <label htmlFor="form-name">Il tuo nome</label>
                                                    <input type="text" id="form-name" value={name} onChange={(e) => setName(e.target.value)} className="form-control" placeholder="Il tuo nome" />
                                                </div>
                                                <div className="form-group col-md-6">
                                                    <label htmlFor="form-email">Email</label>
                                                    <input
                                                        type="email"
                                                        required={true}
                                                        id="form-email"
                                                        value={email}
                                                        onChange={(e) => setEmail(e.target.value)}
                                                        className="form-control"
                                                        placeholder="Indirizzo Email"
                                                    />
                                                </div>
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor="form-subject">Oggetto</label>
                                                <input type="text" value={subject} onChange={(e) => setSubject(e.target.value)} id="form-subject" className="form-control" placeholder="Oggetto" />
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor="form-message">Messaggio</label>
                                                <textarea value={message} onChange={(e) => setMessage(e.target.value)} id="form-message" className="form-control" rows="4" />
                                            </div>
                                            <ReCAPTCHA
                                                onErrored={() => setCaptcha(false)}
                                                onExpired={() => setCaptcha(false)}
                                                className={'pb-4'}
                                                sitekey="6LeGCNImAAAAALUaCQrTK1cnF2UXqMmQsfkHGOXC"
                                                onChange={() => setCaptcha(true)} // Funzione di callback per gestire il cambio di stato
                                            />
                                            <button disabled={!isFormValid()} type="submit" className="btn btn-primary">Invia Messaggio</button>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}

export default SitePageContactUs;
