// react
import React from 'react';

// third-party
import { Helmet } from 'react-helmet-async';
import { Link } from 'react-router-dom';

// data stubs
import theme from '../../data/theme';

function SitePageNotFound() {
    return (
        <div className="block">
            <Helmet>
                <title>{`404 Pagina Non Trovata — ${theme.name}`}</title>
            </Helmet>

            <div className="container">
                <div className="not-found">
                    <div className="not-found__404">
                        Oops! Errore 404
                    </div>

                    <div className="not-found__content">
                        <h1 className="not-found__title">Pagina Non Trovata</h1>

                        <p className="not-found__text">
                            Non siamo riusciti a trovare la pagina che stavi cercando.
                        </p>

                        {/*<form className="not-found__search">*/}
                        {/*    <input type="text" className="not-found__search-input form-control" placeholder="Search Query..." />*/}
                        {/*    <button type="submit" className="not-found__search-button btn btn-primary">Search</button>*/}
                        {/*</form>*/}

                        <p className="not-found__text">
                            Torna alla home page e rinizia da capo.
                        </p>

                        <Link to="/" className="btn btn-secondary btn-sm">Vai Alla Home Page</Link>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default SitePageNotFound;
