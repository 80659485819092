import React, {useEffect, useRef, useState} from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {Link} from 'react-router-dom';
import departmentsAria from '../../services/departmentsArea';
import languages from '../../i18n';
import StroykaSlick from '../shared/StroykaSlick';
import {useProductsContext} from "../../providers/DataProvider";
import {getMediaCallsUrl} from "../../utils/localSwitch";

const slickSettings = {
    dots: true,
    arrows: false,
    infinite: true,
    speed: 400,
    slidesToShow: 1,
    slidesToScroll: 1,
};

const BlockSlideShow = ({locale, withDepartments}) => {
    const departmentsAreaRef = useRef(null);
    const media = window.matchMedia('(min-width: 992px)');
    const direction = languages[locale].direction;

    const {stateData} = useProductsContext();
    const {customHeroSlides} = stateData;

    const [width, setWidth] = useState(window.innerWidth);

    function handleWindowSizeChange() {
        setWidth(window.innerWidth);
    }
    useEffect(() => {
        window.addEventListener('resize', handleWindowSizeChange);
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        }
    }, []);

    const isMobile = width <= 768;

    useEffect(() => {
        const onChangeMedia = () => {
            if (media.matches) {
                departmentsAria.area = departmentsAreaRef.current;
            }
        };

        if (media.addEventListener) {
            media.addEventListener('change', onChangeMedia);
        } else {
            // noinspection JSDeprecatedSymbols
            media.addListener(onChangeMedia);
        }

        return () => {
            departmentsAria.area = null;

            if (media.removeEventListener) {
                media.removeEventListener('change', onChangeMedia);
            } else {
                // noinspection JSDeprecatedSymbols
                media.removeListener(onChangeMedia);
            }
        };
    }, [locale, media]);

    const setDepartmentsAreaRef = (ref) => {
        departmentsAreaRef.current = ref;

        if (media.matches) {
            departmentsAria.area = departmentsAreaRef.current;
        }
    };

    const blockClasses = classNames('block-slideshow block', {
        'block-slideshow--layout--full': !withDepartments,
        'block-slideshow--layout--with-departments': withDepartments,
    });

    const layoutClasses = classNames('col-12', {
        'col-lg-12': !withDepartments,
        'col-lg-9': withDepartments,
    });

    const slides =
        customHeroSlides.map((slide) => {
            return {
                title: slide.attributes.title,
                text: slide.attributes.description,
                image_classic: {
                    ltr: getMediaCallsUrl() + slide.attributes.classicImg.data.attributes.url,
                    rtl: getMediaCallsUrl() + slide.attributes.classicImg.data.attributes.url,
                },
                image_full: {
                    ltr: getMediaCallsUrl() + slide.attributes.imageFull840x395.data.attributes.url,
                    rtl: getMediaCallsUrl() + slide.attributes.imageFull840x395.data.attributes.url,
                },
                image_mobile: {
                    ltr: getMediaCallsUrl() + slide.attributes.imageMobile510x395.data.attributes.url,
                    rtl: getMediaCallsUrl() + slide.attributes.imageMobile510x395.data.attributes.url,
                },
                link : slide.attributes.link,
                link_text : slide.attributes.linkTitle
            }
        }).map((slide, index) => {
            const image = slide.image_full["ltr"];

            const isExternalLink = slide.link.startsWith('https://');
            const linkTarget = isExternalLink ? '_blank' : '';

            return (
                <div key={index} className="block-slideshow__slide">
                    <div
                        className="block-slideshow__slide-image block-slideshow__slide-image--desktop"
                        style={{
                            backgroundImage: `url(${image})`,
                        }}
                    />
                    <div
                        className="block-slideshow__slide-image block-slideshow__slide-image--mobile"
                        style={{
                            backgroundImage: `url(${slide.image_mobile[direction]})`,
                        }}
                    />
                    <div className="block-slideshow__slide-content">
                        <div
                            className={`block-slideshow__slide-title ${!isMobile ? 'text-white' : ''}`}
                            dangerouslySetInnerHTML={{__html: slide.title}}
                        />
                        <div
                            className={`block-slideshow__slide-text ${!isMobile ? 'text-white' : ''}`}
                            dangerouslySetInnerHTML={{__html: slide.text}}
                        />
                        <div className="block-slideshow__slide-button">
                            <a href={slide.link} target={linkTarget} className="btn btn-primary btn-lg">
                                {slide.link_text}
                            </a>
                        </div>
                    </div>
                </div>
            );
        });

    return (
        <div className={blockClasses}>
            <div className="container">
                <div className="row">
                    {withDepartments && (
                        <div className="col-3 d-lg-block d-none" ref={setDepartmentsAreaRef}/>
                    )}

                    <div className={layoutClasses}>
                        <div className="block-slideshow__body">
                            <StroykaSlick {...slickSettings}>{slides}</StroykaSlick>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

BlockSlideShow.propTypes = {
    withDepartments: PropTypes.bool,
    /** current locale */
    locale: PropTypes.string,
};

BlockSlideShow.defaultProps = {
    withDepartments: false,
};

const mapStateToProps = (state) => ({
    locale: state.locale,
});

export default connect(mapStateToProps)(BlockSlideShow);
