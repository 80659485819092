import React, {useEffect, useState} from 'react';
import {Helmet} from 'react-helmet-async';
import theme from '../../data/theme';
import {useUserInfoContext} from "../../providers/UserInfoProvider";
import {toast} from "react-toastify";
import axios from "axios";

export default function AccountPageProfile() {
    const {stateData, reloadUserInfo} = useUserInfoContext();
    const {name, surname, email: r_email, phone: r_phone, id} = stateData;

    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [disabled, setDisabled] = useState(true);

    const handleEdit = (key, value) => {
        switch (key) {
            case 'name':
                setFirstName(value);
                break;
            case 'lastName':
                setLastName(value);
                break;
            case 'email':
                setEmail(value);
                break;
            case 'phone':
                setPhone(value);
                break;
            default:
                break;
        }
    }

    const validateForm = () => {
        return firstName !== '' && lastName !== '' && email !== '' && phone !== '';
    }

    const handleSubmit = async () => {
        try {
            if (validateForm()) {
                const res = await axios.put(
                    `${process.env.REACT_APP_STRAPI_URL}e-commerce-users/${id}`,
                    {
                        data: {
                            name: firstName,
                            surname: lastName,
                            phone
                        }
                    },
                    {
                        headers: {
                            Authorization: `Bearer ${process.env.REACT_APP_STRAPI_TOKEN}`
                        },
                    }
                );
                if (res.status === 200) {
                    toast.success(`Profilo modificato correttamente!`, {theme: 'colored'});
                    reloadUserInfo({
                        name: firstName,
                        surname: lastName,
                        phone
                    })
                } else {
                    throw new Error('Errore nell\' invio dei dati.')
                }
            } else {
                throw new Error('Form non valida!');
            }
        } catch (e) {
            toast.error(`Errore : ${e}`, {theme: 'colored'});
            console.log(e);
        }
    }

    useEffect(() => {
        if (firstName === '') setFirstName(name);
        if (lastName === '') setLastName(surname);
        if (r_email) setEmail(r_email);
        if (phone === '') setPhone(r_phone);
    }, [name, surname, r_email, r_phone]);

    useEffect(() => {
        validateForm() ? setDisabled(false) : setDisabled(true)
    }, [firstName, lastName, email, phone])
    return (
        <div className="card">
            <Helmet>
                <title>{`Profile — ${theme.name}`}</title>
            </Helmet>

            <div className="card-header">
                <h5>Modifica Profilo</h5>
            </div>
            <div className="card-divider"/>
            <div className="card-body">
                <div className="row no-gutters">
                    <div className="col-12 col-lg-7 col-xl-6">
                        <div className="form-group">
                            <label htmlFor="profile-first-name">Nome</label>
                            <input
                                id="profile-first-name"
                                type="text"
                                value={firstName}
                                onChange={(e) => handleEdit('name', e.target.value)}
                                className="form-control"
                                placeholder={"Nome"}
                            />
                        </div>
                        <div className="form-group">
                            <label htmlFor="profile-last-name">Cognome</label>
                            <input
                                id="profile-last-name"
                                type="text"
                                value={lastName}
                                onChange={(e) => handleEdit('lastName', e.target.value)}
                                className="form-control"
                                placeholder={"Cognome"}
                            />
                        </div>
                        <div className="form-group">
                            <label htmlFor="profile-email">Email</label>
                            <input
                                id="profile-email"
                                disabled={true}
                                type="email"
                                value={email}
                                onChange={(e) => handleEdit('email', e.target.value)}
                                className="form-control"
                                placeholder={"Email"}
                            />
                        </div>
                        <div className="form-group">
                            <label htmlFor="profile-phone">Numero Di Telefono</label>
                            <input
                                id="profile-phone"
                                type="text"
                                value={phone}
                                onChange={(e) => handleEdit('phone', e.target.value)}
                                className="form-control"
                                placeholder={"Numero Di Telefono"}
                            />
                        </div>

                        <div className="form-group mt-5 mb-0">
                            <button
                                disabled={disabled}
                                type="button"
                                onClick={handleSubmit}
                                className="btn btn-primary"
                            >
                                Salva
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
