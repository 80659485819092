import {useEffect, useState} from "react";
import {onAuthStateChanged} from "firebase/auth";
import {auth} from "../utils/firebase";

export default function useAuth() {
    const [currentUser, setCurrentUser] = useState();
    useEffect(() => {
        return onAuthStateChanged(auth, (user) =>
            setCurrentUser(user)
        );
    }, []);
    return currentUser;
}
