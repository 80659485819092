// react
import React from 'react';

// third-party
import classNames from 'classnames';
import {connect} from 'react-redux';

// application
import MobileLinks from './MobileLinks';
import {Cross20Svg} from '../../svg';
import {currencyChange} from '../../store/currency';
import {localeChange} from '../../store/locale';
import {mobileMenuClose} from '../../store/mobile-menu';

// data stubs
import mobileMenuLinks from '../../data/mobileMenu';
import {useProductsContext} from "../../providers/DataProvider";
import useAuth from "../../hooks/useAuth";
import {logout} from "../../utils/firebase";
import {Link} from "react-router-dom";

function MobileMenu(props) {
    const {
        mobileMenuState,
        closeMobileMenu,
        changeLocale,
        changeCurrency,
    } = props;

    const classes = classNames('mobilemenu', {
        'mobilemenu--open': mobileMenuState.open,
    });

    const {stateData} = useProductsContext();
    const {categorie} = stateData;
    const currentUser = useAuth();

    const handleItemClick = (item) => {
        if (item.data) {
            if (item.data.type === 'language') {
                changeLocale(item.data.locale);
                closeMobileMenu();
            }
        }
        if (item.type === 'link') {
            closeMobileMenu();
        }
    };
    return (
        <div className={classes}>
            {/* eslint-disable-next-line max-len */}
            {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions,jsx-a11y/click-events-have-key-events */}
            <div className="mobilemenu__backdrop" onClick={closeMobileMenu}/>
            <div className="mobilemenu__body">
                <div className="mobilemenu__header">
                    <div className="mobilemenu__title">Menu</div>
                    <button type="button" className="mobilemenu__close" onClick={closeMobileMenu}>
                        <Cross20Svg/>
                    </button>
                </div>
                <div className="mobilemenu__content">
                    <MobileLinks
                        links={[
                            {
                                type: 'link',
                                label: 'Preferiti',
                                url: '/shop/wishlist',
                                children: [],
                            },
                            {
                                type: 'link',
                                label: 'Catalogo',
                                url: '/shop/catalog',
                                children: [],
                            },
                            {
                                type: 'link',
                                label: 'Categorie',
                                url: '',
                                children: [{
                                    type: 'link',
                                    label: 'Rifacimento Bagno',
                                    url: '/shop/category/' + 'rifacimento-bagno',
                                    children: [],
                                },...categorie.map((c) => ({
                                    type: 'link',
                                    label: c.attributes.title,
                                    url: '/shop/category/' + c.attributes.slug,
                                    children: [],
                                }))],
                            },
                            ...mobileMenuLinks,
                        ]}
                        onItemClick={handleItemClick}
                    />
                </div>
                <div className="mobilemenu__footer">
                    <ul className="mobile-links mobile-links--level--0">
                        {!currentUser ? <li>
                            <div className="mobile-links__item">
                                <div className="mobile-links__item-title"><Link onClick={closeMobileMenu} className="mobile-links__item-link" to={'/account/login'}>Login</Link></div>
                            </div>
                        </li> : <li>
                            <div className="mobile-links__item">
                                <div className="mobile-links__item-title"><a className="mobile-links__item-link"
                                                                             onClick={(e) => {
                                                                                 e.preventDefault();
                                                                                 logout();
                                                                             }}>Logout</a></div>
                            </div>
                        </li>}
                        {currentUser && <li>
                            <div className="mobile-links__item">
                                <div className="mobile-links__item-title"><Link onClick={closeMobileMenu} className="mobile-links__item-link" to={'/account'}>Account</Link></div>
                            </div>
                        </li>}
                        {currentUser && <li>
                            <div className="mobile-links__item">
                                <div className="mobile-links__item-title"><a className="mobile-links__item-link"
                                                                             href="#">{currentUser.email}</a></div>
                            </div>
                        </li>}
                    </ul>
                </div>
            </div>
        </div>
    );
}

const mapStateToProps = (state) => ({
    mobileMenuState: state.mobileMenu,
});

const mapDispatchToProps = {
    closeMobileMenu: mobileMenuClose,
    changeLocale: localeChange,
    changeCurrency: currencyChange,
};

export default connect(mapStateToProps, mapDispatchToProps)(MobileMenu);
