// react
import React from 'react';
import {Oval} from "react-loader-spinner";

export default function BlockLoader() {
    return (
        <div className="block-loader">
            <Oval
                height={80}
                width={80}
                color="#d34748"
                wrapperStyle={{}}
                wrapperClass=""
                visible={true}
                ariaLabel='oval-loading'
                secondaryColor="#f1f1f1"
                strokeWidth={2}
                strokeWidthSecondary={2}
            />
        </div>
    );
}
