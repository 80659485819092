// react
import React from 'react';

// application
import FooterContacts from './FooterContacts';
import FooterLinks from './FooterLinks';
import FooterNewsletter from './FooterNewsletter';
import ToTop from './ToTop';

// data stubs
import theme from '../../data/theme';

export default function Footer() {
    const informationLinks = [
        { title: 'Su Di Noi', url: '/site/about-us' },
        { title: 'Privacy Policy', url: '/privacy-policy' },
        { title: 'Contatti', url: '/site/contact-us' },
    ];

    return (
        <div className="site-footer">
            <div className="container">
                <div className="site-footer__widgets">
                    <div className="row">
                        <div className="col-12 col-md-4 col-lg-4">
                            <FooterContacts />
                        </div>
                        <div className={"col-12 col-md-4 col-lg-5"}>

                        </div>
                        <div className="col-12 col-md-4 col-lg-3">
                            <FooterNewsletter/>
                            <FooterLinks title="Informazioni" items={informationLinks} />
                        </div>
                    </div>
                </div>

                <div className="site-footer__bottom">
                    <div className="site-footer__copyright">
                        Powered by
                        {' '}
                        <a href={'https://react.dev/'} target="_blank" rel="noopener noreferrer">
                            React
                        </a>
                        {' and '}
                        <a href={theme.author.profile_url} target="_blank" rel="noopener noreferrer">
                            {theme.author.name}
                        </a>
                    </div>
                    <div className="site-footer__copyright">
                        P.IVA : {theme.p_iva}
                    </div>
                </div>
            </div>

            <ToTop />
        </div>
    );
}
